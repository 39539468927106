
.title-text {
    color: #16113F;
    font-size: 16px;
}
.person-info {
    display: flex;
    align-items: center;
    .avatar-box {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        overflow: hidden;
        background: #F1F0FE;
        flex-shrink: 0;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .name {
        margin-left: 10px;
        width: calc(100% - 48px);
        min-width: 120px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}
