
.interactive-content-module {
    width: 100%;
    padding: 14px 40px;
    box-sizing: border-box;
    .interactive-content-select {
        margin-bottom: 15px;
        .platform-select {
            width: 136px;
            padding-right: 40px;
            ::v-deep .el-input__inner {
                height: 30px;
                line-height: 30px;
            }
            ::v-deep .el-input__icon {
                line-height: 30px;
            }
        }
    }
    .interactive-content-list {
        .interactive-content-tab {
            ::v-deep .el-tabs__item {
                height: 45px;
                line-height: 45px;
                font-size: 16px;
                font-weight: 400;
                border: none;
                color: #16113F;
            }

            ::v-deep .el-tabs__active-bar {
                background: #9ACC5B;
            }

            ::v-deep .el-tabs__active-bar:before {
                position: absolute;
                top: -13px;
                left: 40%;
                content: '';
                width: 0;
                height: 0;
                border-style: dashed dashed solid dashed;
                border-width: 7px;
                border-color: transparent transparent #9ACC5B transparent;
            }
        }
    }
}
