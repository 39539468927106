
.interactive-manage {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 100px);
    .interactive-content {
        display: flex;
        justify-content: start;
        height: 1%;
        flex: 1;
        .content-left {
            display: flex;
            flex: 1;
            width: 1%;
            border-radius: 10px;
            background-color: #fff;
        }
    }
}
