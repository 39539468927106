
.title-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #16113F;
    font-size: 16px;
}
.video-current {
    margin-top: 16px;
    background: #EFEEFF;
    border-radius: 4px;
    display: flex;
    padding: 10px 20px;
    .video-cover {
        width: 89px;
        height: 114px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        img {
            max-width: 100%;
            max-height: 100%;
        }
        .play-img {
            position: absolute;
            width: 24px;
            height: 24px;
        }
    }
    .video-info {
        width: 1%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 10px 0 10px 16px;
        .title {
            height: 1%;
            flex: 1;
            font-size: 16px;
        }
        .publish-time {
            color: #BCB9CB;
        }
    }
}
::v-deep .el-drawer {
    &.video-drawer {
        outline: none;
        width: 448px!important;
        .el-drawer__body {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        .video-title {
            padding: 24px 24px 20px;
            display: flex;
            justify-content: space-between;
            .title {
                font-size: 20px;
                color: #161823;
            }
            .info {
                font-size: 14px;
                line-height: 20px;
                color: rgba(51,51,51,0.5);
            }
        }
        .video-content {
            margin-left: 24px;
            height: calc(100% - 72px);
            .el-scrollbar__wrap {
                overflow-x: hidden;
            }
            .video-item {
                display: flex;
                background: #fff;
                box-shadow: -1px 0 0 rgba(22,24,35,0.15);
                border-radius: 0 8px 8px 0;
                padding: 14px 25px 14px 16px;
                cursor: pointer;
                &.current {
                    background: rgba(22,24,35,0.03);
                }
                .video-cover {
                    width: 60px;
                    height: 80px;
                    border-radius: 2px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                .video-info {
                    width: 1%;
                    flex: 1;
                    margin-left: 8px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .title {
                        color: #161823;
                        font-size: 14px;
                        line-height: 20px;
                        font-weight: 500;
                    }
                    .publish-time {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 20px;
                        color: #333;
                        mix-blend-mode: normal;
                        opacity: .4;
                    }
                }
                .video-comment {
                    display: flex;
                    width: 58px;
                    margin-right: -25px;
                    align-items: center;
                    flex-direction: column;
                    font-size: 12px;
                    color: rgba(28,31,35,0.6);
                    .iconfont {
                        font-size: 20px;
                        margin: 3px 0;
                    }
                }
            }
            .load-more {
                text-align: center;
                margin: 0 0 82px;
                padding-top: 34px;
                font-size: 16px;
                color: #999;
            }
        }
    }
}
.comment-wrapper {
    margin-top: 30px;
    .module-title {
        color: #16113F;
        font-size: 16px;
    }
    .reply-wrapper {
        display: flex;
        margin-top: 16px;
        border-bottom: 1px solid #eee;
        padding-bottom: 16px;
        .user-avatar {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                max-height: 100%;
                max-width: 100%;
            }
        }
        .reply-content {
            width: 1%;
            flex: 1;
            margin-left: 16px;
            .reply-box {
                margin-top: 5px;
                ::v-deep .el-input__inner {
                    background: #F1F0FE;
                    border: none;
                }
            }
            .reply-btn {
                text-align: right;
                margin-top: 10px;
            }
        }
    }
    .comment-list {
        .comment-item {
            padding: 16px 0;
            display: flex;
            border-bottom: 1px solid #eee;
            &:last-child {
                border-bottom: none;
            }
            .user-avatar {
                width: 48px;
                height: 48px;
                border-radius: 50%;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .comment-content {
                width: 1%;
                flex: 1;
                margin-left: 12px;
                .name {
                    font-size: 16px;
                    margin-top: 5px;
                }
                .time {
                    margin-top: 10px;
                    font-size: 12px;
                    color: #999;
                }
                .content {
                    font-size: 16px;
                    margin-top: 15px;
                }
                .reply-btn {
                    margin-top: 16px;
                    .btn {
                        cursor: pointer;
                        display: inline-flex;
                        align-items: center;
                        font-size: 12px;
                        color: #BCB9CB;
                        .iconfont {
                            margin-right: 4px;
                        }
                    }
                }
                .open-btn {
                    font-size: 12px;
                    margin-top: 40px;
                    color: rgba(28,31,35,0.6);
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    .line {
                        margin-right: 4px;
                        color: rgba(22,24,35,0.06);
                    }
                    .iconfont {
                        margin-left: 4px;
                    }
                }
            }
        }
    }
}
.no-data {
    text-align: center;
    margin-top: 30px;
    color: #999;
}
