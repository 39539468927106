
.list-text {
    color: #16113F;
    font-size: 16px;
}
.attention-table {
    ::v-deep .el-table {
        ::v-deep .el-table__header-wrapper {
            ::v-deep .el-table__header {
                tr {
                    th {
                        .cell {
                            padding-left: 0;
                        }
                    }
                }
            }
        }
    }
    ::v-deep .el-table__row {
        td:first-child .cell {
            display: flex;
            justify-content: start;
            align-items: center;
            cursor: pointer;
            .video-cover {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 50px;
                min-width: 50px;
                height: 40px;
                margin: 0 10px 0 0;
                padding: 0;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
    }
}
.view-more {
    display: block;
    width: 100%;
    text-align: center;
    cursor: pointer;
    &:hover {
        color: #2338E6;
    }
}
.no-more {
    display: block;
    width: 100%;
    text-align: center;
    padding-bottom: 10px;
}
.person-info {
    display: flex;
    align-items: center;
    width: 100%;
    .avatar-box {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        overflow: hidden;
        background: #F1F0FE;
        flex-shrink: 0;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .name {
        margin-left: 10px;
        width: calc(100% - 48px);
        min-width: 120px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}
